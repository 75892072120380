import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import RobotsView from './components/RobotsView';
import AppsView from './components/AppsView';
import Lost404View from './components/Lost404View';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<RobotsView/>}></Route>
        <Route path='/:ip_addr' element={<AppsView/>}></Route>
        <Route path='/*' element={<Lost404View/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
