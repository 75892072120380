import React from "react";
import { withRouter } from './withRouter';
var isEqual = require('lodash.isequal');

import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Placeholder from 'react-bootstrap/Placeholder';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';


class AppsView extends React.Component {

    constructor(props) {
        super(props);
        console.log(`Running frontend v${process.env.REACT_APP_VERSION}`);

        const {ip_addr} = props.params;
        let http_uri = `//${ip_addr}:5000`;
        let https_uri = `//${ip_addr.replaceAll('.', '-')}.my.local-ip.co:5000`;
        let do_https_redirect = true;
        if (window.localStorage.getItem('https_redirect')) {
            do_https_redirect = window.localStorage.getItem('https_redirect') == "true" ;
        }
        this.state = {
            ip_addr: ip_addr,
            base_uri: ((do_https_redirect) ? https_uri : http_uri),
            fetch_failure: false,
            version: 'v1',
            oldsiteversion_failure: false,
            status_looping: false,
            robot_name: undefined,
            app_name: undefined,
            available_apps: undefined,
            toast_errmsg: undefined,
            toast_errtitle: undefined,
            show_update_card: false,
            update_card_loading: false,
            show_restarting_overlay: false
        };

        this.showErrorAndReload = this.showErrorAndReload.bind(this);
        this.handleSwitchApp = this.handleSwitchApp.bind(this);
        this.closeErrorToast = this.closeErrorToast.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

        fetch(`${this.state.base_uri}/whoami`).then(response=>response.text()).then(robotname=>{
            this.setState({ robot_name: robotname });
            fetch(`${this.state.base_uri}/version`).then(response=>response.text()).then(server_version=>{
                if (server_version > this.state.version) {
                    this.setState({ oldsiteversion_failure: true });
                } else if (this.state.version > server_version) {
                    this.setState({ show_update_card: true });
                } else {
                    this.setState({ status_looping: true });
                    fetch(`${this.state.base_uri}/available_apps`).then(response=>{
                        if (!response.ok) {
                            response.text().then(errmsg=>{
                                this.setState({ toast_errmsg: errmsg, toast_errtitle: "Failed to find apps" });
                            });
                        } else {
                            return response.json();
                        }
                    }).then(newly_available_apps=>{
                        this.setState({ available_apps: newly_available_apps });
                    }).catch(error=>{
                        this.showErrorAndReload();
                    });
                }
            }).catch(error=>{
                this.showErrorAndReload();
            });
        }).catch(error=>{
            this.showErrorAndReload();
        });
    }

    showErrorAndReload() {
        this.setState({ fetch_failure: true });
        setTimeout(()=>{
            window.location.reload();
        }, 3000);
    }

    handleSwitchApp(selected_app) {
        let app_name = selected_app.name;
        let docker_repoimagetag = `${selected_app.repo}/${selected_app.name}:${selected_app.tag}`;
        this.setState(prevState => ({
            available_apps: prevState.available_apps.map(a => (isEqual(a, selected_app) ? {...a, switching: true } : a))
        }));
        fetch(`${this.state.base_uri}/switch_app/${app_name}/${docker_repoimagetag}`).then(response=>{
            selected_app.switching = true;
            this.setState(prevState => ({
                available_apps: prevState.available_apps.map(a => (isEqual(a, selected_app) ? {...a, switching: false } : a))
            }));
            if (!response.ok) {
                response.text().then(errmsg=>{
                    this.setState({ toast_errmsg: errmsg, toast_errtitle: "Failed to switch app" });
                });
            }
        }).catch(error=>{
            this.showErrorAndReload();
        })
    }

    closeErrorToast() {
        this.setState({ toast_errmsg: undefined });
    }

    handleUpdate() {
        this.setState({ update_card_loading: true, status_looping: false });
        fetch(`${this.state.base_uri}/update`).then(response=>{
            this.setState({ update_card_loading: false });
            if (!response.ok) {
                response.text().then(errmsg=>{
                    this.setState({ toast_errmsg: errmsg, toast_errtitle: "Failed to update" });
                });
            } else {
                this.setState({ show_restarting_overlay: true });
                fetch(`${this.state.base_uri}/restart`).then(response=>{
                    setTimeout(()=>{
                        window.location.reload();
                    }, 5000);
                }).catch(error=>{
                    this.showErrorAndReload();
                });
            }
        }).catch(error=>{
            this.showErrorAndReload();
        });
    }

    render() {
        return (
            <Container className="mt-4">
                <Stack direction="horizontal">
                    <p className='fs-5 fw-bolder mb-1'>Robot:&nbsp;</p>
                    { this.state.robot_name && <p className='fs-5 mb-1'>{this.state.robot_name}</p> }
                    { !this.state.robot_name &&
                    <Placeholder as="p" animation="glow" className="mb-1">
                        <Placeholder style={{ width: 200, height: 22 }} />
                    </Placeholder> }
                </Stack>
                <Stack direction="vertical" gap={0}>
                    <p className='fs-5 fw-bolder mb-0'>Currently Running:&nbsp;</p>
                    { this.state.app_name && <p className='fs-5 mb-0'>{this.state.app_name}</p> }
                    { !this.state.app_name &&
                    <Placeholder as="p" animation="glow" className="mb-0">
                        <Placeholder style={{ width: 300, height: 22 }} />
                    </Placeholder> }
                </Stack>
                <hr />
                { this.state.available_apps && <p className='text-muted fw-light'>Available apps</p> }
                { !this.state.available_apps && <div className='text-muted fw-light'>Searching for apps... <Spinner animation="border" size='sm' /></div> }
                <Stack gap={3} className='mt-3 mb-3'>
                {
                    this.state.available_apps && this.state.available_apps.map((app, idx) => {
                        return (
                            <Card key={idx} bg="light">
                                <Card.Body>
                                    <Card.Title>{app.name}<small className="text-muted fw-light">:{app.tag}</small></Card.Title>
                                    <Card.Subtitle className="text-muted mb-2">{app.date}</Card.Subtitle>
                                    <Card.Text>{app.description}</Card.Text>
                                    <Button variant="primary" disabled={app.switching} onClick={()=>{this.handleSwitchApp(app)}}>
                                    {
                                        app.switching ? (
                                            <div>
                                                Loading... {' '}
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm" />
                                            </div>
                                        ) : 'Launch'
                                    }
                                    </Button>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
                { this.state.show_update_card &&
                    <Card bg="light">
                        <Card.Body>
                            <Card.Title>update_robot<small className="text-muted fw-light">:{this.state.version}</small></Card.Title>
                            <Card.Subtitle className="text-muted mb-2">right now</Card.Subtitle>
                            <Card.Text>The robot must update before the Dev App Store can communicate with it.</Card.Text>
                            <Button variant="success" disabled={this.state.update_card_loading} onClick={()=>{this.handleUpdate()}}>
                            {
                                this.state.update_card_loading ? (
                                    <div>
                                        Loading... {' '}
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm" />
                                    </div>
                                ) : 'Update'
                            }
                            </Button>
                        </Card.Body>
                    </Card>
                }
                </Stack>
                <ToastContainer containerPosition="fixed" position="top-end" className="p-3">
                    <Toast bg={'danger'} show={!!this.state.toast_errmsg} onClose={this.closeErrorToast} autohide={true} delay={20000}>
                        <Toast.Header>
                            <strong className="me-auto">🚫 {this.state.toast_errtitle}</strong>
                        </Toast.Header>
                        <Toast.Body className="text-white">{this.state.toast_errmsg}</Toast.Body>
                    </Toast>
                </ToastContainer>
                { this.state.fetch_failure &&
                <div className="fadeMe col-md-auto d-flex align-items-center">
                    <div className="mx-auto text-center">
                        <h2 className="text-white">🚫 Not found</h2>
                        <p className="text-white">Unable to ping Stretch at {this.state.ip_addr}. Trying again in 3 seconds...</p>
                    </div>
                </div> }
                { this.state.oldsiteversion_failure &&
                <div className="fadeMe col-md-auto d-flex align-items-center">
                    <div className="mx-auto text-center">
                        <h2 className="text-white">🤖 Version mismatch</h2>
                        <p className="text-white">Unable to communicate with Stretch at {this.state.ip_addr} since this website's version is too old. Try clearing your browser's cache.</p>
                    </div>
                </div> }
                { this.state.show_restarting_overlay &&
                <div className="fadeMe col-md-auto d-flex align-items-center">
                    <div className="mx-auto text-center">
                        <h2 className="text-white">🌘 Restarting</h2>
                        <p className="text-white">The robot has finished updating. The server will now restart. This page will automatically refresh in 5 seconds...</p>
                    </div>
                </div> }
            </Container>
        );
    }

    componentDidMount() {
        this.interval = setInterval(()=>{
            if (this.state.status_looping) {
                fetch(`${this.state.base_uri}/running_appname`).then(response=>response.text()).then(appname=>{
                    this.setState({ app_name: appname });
                }).catch(error=>{
                    this.showErrorAndReload();
                });
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

}

export default withRouter(AppsView);
