import React from "react";


function Lost404View() {
    return (
        <p>404: The requested URL was not found on this server.</p>
    );
}

export default Lost404View;
