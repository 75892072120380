// https://stackoverflow.com/a/70518924
import { useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    
    return (
      <Component
        navigate={navigate}
        {...props}
        params={useParams()} />
    );
  };
  
  return Wrapper;
};
