import React from 'react';
import { withRouter } from './withRouter';
import '../webscan';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';


class RobotsView extends React.Component {

    constructor(props) {
        super(props);
        console.log(`Running frontend v${process.env.REACT_APP_VERSION}`);

        // example discovered robot: {'ip': '192.168.0.106', 'name': 'stretch-re1-1002'}
        this.state = { discovered_robots: [] };

        this.handleIPAddrFormSubmit = this.handleIPAddrFormSubmit.bind(this);
        this.ipAddrToBaseUri = this.ipAddrToBaseUri.bind(this);
        this.handleNetworkCallback = this.handleNetworkCallback.bind(this);

        let ipsToScan = undefined; // array. if undefined, scan major subnet gateways, then scan live subnets. supports wildcards
        webScanAll(
            ipsToScan,
            {
                rtc: true,   // use webrtc to detect local ips
                logger: l => { console.log(l); },  // logger callback
                noRedirect: true, // if true, doesn't redirect from https to http - Chrome doesn't scan detect network IPs proprly on https atm
                localCallback:   function(ip) { },
                subnetCallback:  function(ip) { },
                networkCallback: this.handleNetworkCallback,
            }
        )
    }

    handleIPAddrFormSubmit(event) {
        event.preventDefault();
        this.props.navigate(`/${event.target.ipaddr.value}`);
    }

    ipAddrToBaseUri(ip_addr) {
        let http_uri = `//${ip_addr}:5000`;
        let https_uri = `//${ip_addr.replaceAll('.', '-')}.my.local-ip.co:5000`;
        let do_https_redirect = true;
        if (window.localStorage.getItem('https_redirect')) {
            do_https_redirect = window.localStorage.getItem('https_redirect') == "true" ;
        }
        return ((do_https_redirect) ? https_uri : http_uri);
    }

    handleNetworkCallback(ip) {
        fetch(`${this.ipAddrToBaseUri(ip)}/whoami`).then(response=>response.text()).then(robot_name=>{
            let discovered_robot = { 'ip': ip, 'name': robot_name };
            this.setState(prevState => ({discovered_robots: [...prevState.discovered_robots, discovered_robot]}));
        })
    }

    render() {
        return (
            <Container>
                <Form className="mt-4" onSubmit={this.handleIPAddrFormSubmit}>
                    <Form.Group controlId='formgroup_ipaddr' className="mb-3">
                        <Form.Label className='mb-0 fs-5 fw-bolder'>Enter IP Address</Form.Label> <br></br>
                        <Form.Text className='text-muted fw-light'>If you know your robot's IP address, enter it here.</Form.Text>
                        <Row className='mt-3'>
                            <Col xs="8">
                                <Form.Control type='text' placeholder='e.g. 192.168.0.1' autoComplete='off' name='ipaddr' />
                            </Col>
                            <Col>
                                <Button variant='primary' type='submit'>Submit</Button>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
                <hr />
                <div className='text-muted fw-light'>Searching for robots... <Spinner animation="border" size='sm' /></div>
                <Stack gap={3} className='mt-3'>
                {
                    this.state.discovered_robots.map((robot, idx) => {
                        return (
                            <Button key={idx} variant='primary' size='lg' href={`/${robot.ip}`}>{robot.name}</Button>
                        )
                    })
                }
                </Stack>
            </Container>
        );
    }
}

export default withRouter(RobotsView);
